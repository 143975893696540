import { defineStore } from 'pinia';
import type { OrderConfiguration } from '~/store/types';

/**
 * NOTE: potentially deprecated, since it can be replaced with provide/inject in LandingPageWrapper.vue,
 * since it is single purpose and not used anywhere else.
 */
export const useOrderStore = defineStore({
  id: 'order',
  state: () => ({
    orderConfiguration: undefined as OrderConfiguration | undefined,
  }),
  getters: {
    selectedOrderConfiguration: (state) => state.orderConfiguration,
  },
  actions: {
    setSelectedOrderConfiguration(c?: OrderConfiguration) {
      this.orderConfiguration = c;
    },
  },
});
