<template>
  <div>
    <div v-if="strapiContent" class="landing-page">
      <div
        v-if="strapiContent.headerCoverImage"
        class="landing-page__background"
      >
        <strapi-image-overlay
          auto-scale
          :lazy="false"
          :alt-fallback="`${strapiContent.title} Background`"
          :image="strapiContent.headerCoverImage"
        />
      </div>

      <div class="landing-page__content">
        <landing-page-top-bar
          v-if="strapiTopBar.length > 0"
          :benefits="strapiTopBar"
        />

        <div class="container">
          <common-breadcrumb class="mb-0 mt-4 mt-lg-6" :crumbs="breadCrumbs" />
        </div>

        <strapi-product-header
          :class="{
            'mb-16': strapiCountdown == null,
            'mb-6':
              strapiCountdown != null && strapiCountdown.countdown != null,
          }"
          class="mt-4 mt-lg-6"
          :pre-title="strapiContent.subHeadline"
          :default-order-url="strapiContent.orderUrl"
          :pre-order="isPreOrder"
          :product-key="strapiContent.productKey"
          :product-type="strapiContent.type"
          :platform="strapiContent.type"
          :starting="strapiContent.startConfiguration"
          :title="strapiContent.title"
          :microsoft-store="strapiContent.type === StrapiProductType.Xbox"
        />

        <div
          v-if="strapiCountdown != null && strapiCountdown.countdown != null"
          class="index-page__countdown-wrapper"
        >
          <lazy-strapi-countdown :component="strapiCountdown.countdown" />
        </div>

        <strapi-product-components :product="strapiContent" />
      </div>
    </div>
    <common-scroll-to-top />
  </div>
</template>

<script lang="ts" setup>
import {
  StrapiProductType,
  type GetProductQuery,
  type StrapiProduct,
  type StrapiProductCountdown,
} from '~/apollo/types/types';
import type { BreadCrumb } from '../common/types';
import useLandingPageData from './composables/useLandingPageData';

const props = defineProps<{
  slug: string;
}>();

const route = useRoute();

const slugRef = toRef(props, 'slug');
const { refresh, landingPageContent, data } = await useLandingPageData(slugRef);

// set SEO
const localizedSlugs = computed(() => landingPageContent.value?.localizedSlugs);
useI18nLocalizedSlugs(localizedSlugs);

const googleProduct = computed(() => data.value.googleProduct);
const config = useRuntimeConfig();
useHead({
  title: landingPageContent.value?.metaTitle,
  link: [
    {
      rel: 'preload',
      as: 'image',
      href: '/sc/images/svgs/landingpage_bg.svg',
    },
  ],
  script: () => {
    const scripts = [];
    if (googleProduct.value) {
      scripts.push({
        type: 'application/ld+json',
        innerHTML: googleProduct.value,
      });
    }
    return scripts;
  },
});

useSeoMeta({
  title: () => landingPageContent.value?.metaTitle,
  description: () => landingPageContent.value?.metaDescription,
  ogTitle: () => landingPageContent.value?.metaTitle,
  ogImage: () => landingPageContent.value?.headerCoverImage.url,
  ogDescription: () => landingPageContent.value?.metaDescription,
  ogUrl: () => `${config.public.websiteUrl}/${route.fullPath}`,
  twitterImage: () => landingPageContent.value?.headerCoverImage.url,
  twitterTitle: () => landingPageContent.value?.metaTitle,
  twitterDescription: () => landingPageContent.value?.metaDescription,
});

const { scrollToTop } = useWindowScroll();

// watch currency change -> re-fetch content
useUserCurrency(refresh, async () => {
  await refresh();
  scrollToTop();
});

const breadCrumbs = computed<Array<BreadCrumb>>(() => data.value.breadCrumbs);
const strapiContent = computed<StrapiProduct>(() => data.value.strapiContent);
const strapiTopBar = computed<GetProductQuery['product_top_bar']>(
  () => data.value.strapiTopBar,
);
const strapiCountdown = computed<StrapiProductCountdown>(
  () => data.value.strapiCountdown as StrapiProductCountdown,
);

const isPreOrder = computed<boolean>(() =>
  timeIsAfterNow(data.value.strapiContent?.releaseAt),
);
</script>

<style lang="scss">
@import 'gportal-theme/scss/colors';

.landing-page {
  position: relative;
  background-image: url(/sc/images/svgs/landingpage_bg.svg);
  background-color: $gp-legacy-background;
  background-repeat: repeat;

  .container {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;

    img {
      mask-image: linear-gradient(
        to top,
        rgba(255, 0, 0, 0),
        rgba(255, 255, 255, 255)
      );
      height: auto;
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  &__content {
    position: relative;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
  }
}
</style>
