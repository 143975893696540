import {
  GetProductDocument,
  StrapiProductType,
  type GetProductQuery,
  type GetProductQueryVariables,
  type StrapiProduct,
} from '~/apollo/types/types';
import type { BreadCrumb } from '~/components/common/types';
import { useGoogleProduct } from '~/helpers/googleProduct';

enum LP_FETCH_ERROR {
  NOT_FOUND = 'NOT_FOUND',
  RESPONSE_ERROR = 'RESPONSE_ERROR',
}

export default async (slugRef: Ref<string>) => {
  const { locale, t } = useI18n();
  const { $sentry } = useNuxtApp();
  const currencyStore = useCurrencyStore();
  const route = useRoute();

  const { refresh, data, error } = await useAsyncData<{
    googleProduct: string;
    strapiContent: StrapiProduct;
    strapiTopBar: GetProductQuery['product_top_bar'];
    strapiCountdown: GetProductQuery['product_countdown'];
    breadCrumbs: Array<BreadCrumb>;
  }>(
    `gp-landing-page`,
    async () => {
      const ret = {
        googleProduct: null,
        strapiContent: null,
        strapiTopBar: [],
        strapiCountdown: null,
        breadCrumbs: [],
      };

      try {
        const { error: apolloError, data: response } = await useLegacyApollo<
          GetProductQuery,
          GetProductQueryVariables
        >(GetProductDocument, {
          locale: locale.value,
          currency: currencyStore.currency,
          slugs: [`.*\\/${slugRef.value}$`],
        });

        if (apolloError != null || response == null) {
          $sentry.captureException(apolloError);
          throw new Error(LP_FETCH_ERROR.NOT_FOUND);
        }

        if (response.products.length !== 1) {
          throw new Error(LP_FETCH_ERROR.RESPONSE_ERROR);
        }

        const lp = response.products[0] as StrapiProduct;
        ret.strapiContent = lp;
        ret.strapiTopBar = response.product_top_bar;
        ret.strapiCountdown = response.product_countdown;
        ret.googleProduct = JSON.stringify(
          useGoogleProduct(route.fullPath, response),
        );

        if (
          ret.strapiContent.type !== StrapiProductType.Voiceserver &&
          !ret.breadCrumbs.some((b) => b.link === 'gameserver/index')
        ) {
          ret.breadCrumbs.push({
            title: t('general/menu/item/game_servers') as string,
            link: 'gameserver/index',
          });
        }

        if (!ret.breadCrumbs.some((b) => b.link === slugRef.value)) {
          ret.breadCrumbs.push({
            title: ret.strapiContent.title,
            link: slugRef.value,
          });
        }
      } catch (e) {
        $sentry.captureException(e);
        throw e;
      }

      return ret;
    },
    {
      default: () => ({
        googleProduct: null,
        strapiContent: null,
        strapiTopBar: [],
        strapiCountdown: null,
        breadCrumbs: [],
      }),
    },
  );

  const navigateToGameserverIndex = async () => {
    await navigateTo({
      name: route.name,
      params: {
        slug: 'index',
      },
    });
  };

  if (error.value != null || data.value.strapiContent == null) {
    // in case we have an error or the landing page content is null, redirect to the gameserver index
    await navigateToGameserverIndex();
  }

  const { redirectToDefaultLocale } = useRedirectToLocaleSlugs(
    data.value?.strapiContent.localizedSlugs,
    'index',
  );
  if (data.value.strapiContent.locale !== locale.value) {
    // in case the locale is not the same as the current locale, redirect to the default locale
    await redirectToDefaultLocale();
  }

  const landingPageContent = computed(() => data.value.strapiContent);

  return {
    data,
    landingPageContent,
    refresh,
  };
};
