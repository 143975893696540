<template>
  <!-- we show the starting price -->
  <span v-if="selectedConfiguration == null">
    <i18n-t v-if="isDiscountedGame" keypath="landingpage/price_starting">
      <!-- this includes strike through price -->
      <template #days>{{ starting.duration }}</template>
      <template #price>
        <s>{{ startingPrice }}</s>
        &nbsp;
        <b>{{ discountedStartingPrice }}</b>
      </template>
    </i18n-t>

    <!-- in case we do not have any discount, just show the regular price with default formatting -->
    <template v-else>
      {{
        t('landingpage/price_starting', {
          days: starting.duration,
          price: startingPrice,
        })
      }}
    </template>
  </span>

  <!-- now we have a selected configuration -->
  <span v-else>
    <i18n-t
      v-if="isDiscountedGame"
      keypath="landingpage/orderwidget/price_summary"
    >
      <!-- this includes strike through price -->
      <template #duration>{{ selectedConfiguration.duration }}</template>
      <template #units>{{ selectedConfiguration.units }}</template>
      <template #unitType>{{ unitsText }}</template>
      <template #price>
        <s>{{ selectedLocationRegularPrice }}</s>
        &nbsp;
        <b>{{ selectedLocationDiscountedPrice }}</b>
      </template>
    </i18n-t>

    <!-- show the regular price -->
    <template v-else>
      {{
        t('landingpage/orderwidget/price_summary', {
          duration: selectedConfiguration.duration,
          units: selectedConfiguration.units,
          unitType: unitsText,
          price: selectedLocationRegularPrice,
        })
      }}
    </template>
  </span>
</template>

<script setup lang="ts">
import type { StrapiProductStartConfiguration } from '~/apollo/types/types';
import { DISCOUNTED_PRODUCT_KEYS } from '../strapi/constants';

const props = defineProps<{
  starting: StrapiProductStartConfiguration;
  productKey: string;
}>();

const { t } = useI18n();
const { DEFAULT_DISCOUNT, formatStrapiPrice, format } =
  useNumberMoneyFormatting();

const isDiscountedGame = computed<boolean>(() =>
  DISCOUNTED_PRODUCT_KEYS.includes(props.productKey),
);

const startingPrice = computed<string>(() =>
  formatStrapiPrice(props.starting.prices[0]),
);
const discountedStartingPrice = computed<string>(() =>
  formatStrapiPrice(props.starting.prices[0], DEFAULT_DISCOUNT),
);

const orderStore = useOrderStore();
const selectedConfiguration = computed(
  () => orderStore.selectedOrderConfiguration,
);

const locationStore = useLocationStore();
const selectedLocationSlug = computed<string>(
  () => locationStore.selectedLocation.slug,
);

/**
 * Shortcut to the price for the selected location
 */
const priceBySelection = computed(() => {
  if (selectedConfiguration.value == null) return null;

  return selectedConfiguration.value.prices.find(
    (l) => l.slug === selectedLocationSlug.value,
  );
});

/**
 * This is the regular price for the selected location
 *
 * In case the the game does not have any discount, this is the only price displayed
 */
const selectedLocationRegularPrice = computed<string>(() => {
  if (priceBySelection.value == null) return null;

  return format(priceBySelection.value.rawPrice);
});

/**
 * this is the discounted price for the selected location
 * it is displayed with the regular price crossed out
 */
const selectedLocationDiscountedPrice = computed<string>(() => {
  if (priceBySelection.value == null) return null;

  return format({
    ...priceBySelection.value.rawPrice,
    // override the price with the discounted price
    amount: Number(
      priceBySelection.value.rawPrice.amount * (1 - DEFAULT_DISCOUNT),
    ),
  });
});

const unitsText = computed<string>(() => {
  if (selectedConfiguration.value == null) return '';

  if (selectedConfiguration.value.unitType === 'GB') {
    return `GB RAM`;
  }

  return t('general/non_special_texts/slot_plural');
});
</script>

<style scoped></style>
