<template>
  <div id="landing-page-order-configurations" class="container">
    <h2>{{ component.title }}</h2>

    <div class="row">
      <div
        v-for="config in component.orderConfigurations.slice(-3)"
        :key="config.id"
        class="col-lg-3 col-sm-6 col-12 py-3 p-sm-2"
        @click.exact="selectConfiguration(config)"
      >
        <landing-page-order-configuration
          :order-configuration="config"
          :product-key="productKey"
          :product-type="productType"
          :pre-order="preOrder"
          :selected="
            selectedConfiguration && selectedConfiguration.id === config.id
          "
        />
      </div>
      <div
        class="col-lg-3 col-sm-6 col-12 py-3 p-sm-2"
        @click.exact="selectConfiguration(undefined)"
      >
        <landing-page-custom-order-configuration
          :order-url="orderUrl"
          :pre-order="preOrder"
          :background-image="component.backgroundImage"
        />
      </div>
      <div v-if="isDiscountedGame" class="row">
        <div class="col-lg-12 col-sm-12 col-12" style="text-align: right">
          * {{ t('landingpage/discount/disclaimer') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  type StrapiOrderConfigurationContent,
  type StrapiOrderConfigurations,
} from '~/apollo/types/types';
import type {
  OrderConfiguration,
  OrderConfigurationLocationPrice,
} from '~/store/types';
import { DISCOUNTED_PRODUCT_KEYS } from '~/components/strapi/constants';

export default defineComponent({
  name: 'StrapiProductOrderConfigurations',
  props: {
    component: {
      type: Object as PropType<StrapiOrderConfigurations>,
      required: true,
    },
    productKey: {
      type: String,
      required: true,
    },
    productType: {
      type: String,
      required: true,
    },
    orderUrl: {
      type: String,
      required: true,
    },
    preOrder: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { format } = useNumberMoneyFormatting();
    const orderStore = useOrderStore();
    const locationStore = useLocationStore();

    /**
     * To make sure that the order now link always has the latest href about the selected location,
     * we need to check if the selected location is available for the selected configuration.
     *
     * @param prices prices coming from the selected order configuration
     */
    const getSelectedLocationInfo = (
      prices: Array<
        OrderConfigurationLocationPrice & {
          orderUrl: string;
        }
      >,
    ): { slug: string; orderUrl: string } => {
      // in case we do not have a selected location, preselect the first one
      // this only happens, if the user clicks on the box for the first time
      // in other cases, the dropdown order configuration is going to handle this
      if (prices.length > 0 && locationStore.selectedLocation.slug == null) {
        const { slug, orderUrl } = prices[0];
        return { slug, orderUrl };
      }

      if (locationStore.selectedLocation != null) {
        const searchLocationPrice = prices.find(
          (l) => l.slug === locationStore.selectedLocation.slug,
        );
        // there is the other case, where the selected location may not be available
        // for the selected configuration, in this case, we need to select the first available
        if (searchLocationPrice == null) {
          const { slug, orderUrl } = prices[0];
          return { slug, orderUrl };
        }

        // otherwise, we find the selected location in the newly selected configuration
        // so we return the slug and the orderUrl for data consistency with StrapiProductHeader
        return {
          slug: searchLocationPrice.slug,
          orderUrl: searchLocationPrice.orderUrl,
        };
      }

      // otherwise, return null
      return null;
    };

    const selectConfiguration = (sc?: StrapiOrderConfigurationContent) => {
      if (sc == null) {
        orderStore.setSelectedOrderConfiguration();
      } else {
        const prices: Array<
          OrderConfigurationLocationPrice & {
            orderUrl: string;
          }
        > = sc.locations
          // skip locations without prices
          .filter((loc) => loc.prices.length > 0)
          .map((loc) => {
            return {
              orderUrl: loc.orderUrl,
              slug: loc.slug,
              rawPrice: {
                amount: loc.prices[0].value,
                currencyCode: loc.prices[0].currency,
                fraction: loc.prices[0].currency === 'JPY' ? 0 : 2,
              },
              price: format({
                amount: loc.prices[0].value,
                currencyCode: loc.prices[0].currency,
                fraction: loc.prices[0].currency === 'JPY' ? 0 : 2,
              }),
            };
          });

        const { slug, orderUrl } = getSelectedLocationInfo(prices);
        locationStore.setLocation({ slug, orderUrl });

        orderStore.setSelectedOrderConfiguration({
          prices,
          id: sc.id,
          duration: sc.duration,
          units: sc.units,
          unitType: sc.unitType,
        });
      }
    };

    const selectedConfiguration = computed<OrderConfiguration | undefined>(
      () => orderStore.selectedOrderConfiguration,
    );

    onUnmounted(() => {
      selectConfiguration();
    });

    const isDiscountedGame = computed<boolean>(() =>
      DISCOUNTED_PRODUCT_KEYS.includes(props.productKey),
    );

    return {
      selectedConfiguration,
      selectConfiguration,
      isDiscountedGame,
      t,
    };
  },
});
</script>
