<template>
  <p v-if="locations.length > 0" class="text-primary fw-bold mb-0">
    {{ $t('landingpage/orderwidget/dropdown/title_other_region') }}:
  </p>

  <!-- location switch -->
  <div
    ref="dropdownContainer"
    class="order-configuration__location position-relative pb-4"
  >
    <button
      v-if="selectedLocation"
      aria-expanded="false"
      class="order-configuration__location-toggle btn dropdown-toggle w-100 position-relative border-bottom px-2 py-1 fs-tiny text-truncate text-start fw-normal d-flex justify-content-between align-items-center"
      data-bs-toggle="dropdown"
      type="button"
      data-cy="country-selector-dropdown"
    >
      <div class="col">
        <common-flag-icon :iso-code="selectedLocation.countryCode" />
        {{ selectedLocation.country }}
        <span v-if="!isCentralEuropeLocation(selectedLocation)">
          ({{ selectedLocation.city }})
        </span>
      </div>
      <div class="w-auto d-flex align-items-center">
        <client-only v-if="selectedLocation.available">
          <common-latency-result
            :slug="selectedLocation.slug"
            class="ps-3 me-1 d-block"
          />
        </client-only>
        <fa-icon
          class="text-rhino d-block"
          :style="{
            transform: dropdownShown ? 'rotate(180deg)' : 'rotate(0deg)',
          }"
          :icon="faChevronDown"
        />
      </div>
    </button>

    <div
      class="order-configuration__location-switch dropdown-menu dropdown-menu-right bg-dark"
    >
      <div
        v-for="location in sortedLocations"
        :key="location.slug"
        :data-cy="dataCyName([location.slug, 'location-btn'])"
        class="option px-2 py-1 d-flex flex-row justify-content-between align-items-center fs-tiny"
        :class="{
          'cursor-pointer anchor-neutral': location.available,
        }"
        @click="() => emit('locationSelected', location)"
      >
        <span
          :class="{
            'text-decoration-line-through': !location.available,
          }"
        >
          <common-flag-icon :iso-code="location.countryCode" />
          {{ location.country }}
          <span v-if="isCentralEuropeLocation(location)"> * </span>
          <span v-else> ({{ location.city }}) </span>
        </span>

        <client-only v-if="location.available">
          <common-latency-result :slug="location.slug" class="ps-3" />
        </client-only>
        <span v-else>
          {{ $t('landingpage/orderwidget/dropdown/sold_out') }}
        </span>
      </div>
      <template v-if="centralEuropeLocation != null">
        <hr class="my-1" />
        <div
          class="option px-2 py-1 d-flex flex-row justify-content-between align-items-center fs-tiny"
          style="white-space: normal"
        >
          * {{ centralEuropeLocation.city }}
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import dataCyName from '~/helpers/cypress';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import type { StrapiLocalizedProductConfiguration } from '~/apollo/types/types';
import type { Dropdown } from 'bootstrap';
import '~/assets/styles/gportal/flags.scss';

const emit = defineEmits<{
  (e: 'locationSelected', val: StrapiLocalizedProductConfiguration): void;
}>();

const props = defineProps<{
  locations: StrapiLocalizedProductConfiguration[];
  selectedLocation?: StrapiLocalizedProductConfiguration;
}>();

const locationStore = useLocationStore();
const latencies = computed<LatencyMeasurement[]>(
  () => locationStore.latencyMeasurements,
);

const sortedLocations = ref<StrapiLocalizedProductConfiguration[]>(
  props.locations,
);

const sortLocations = (input: StrapiLocalizedProductConfiguration[]) => {
  const orderLocationsSorted = [...input];
  if (process.client && latencies.value != null && latencies.value.length > 0) {
    orderLocationsSorted.sort(
      (
        a: StrapiLocalizedProductConfiguration,
        b: StrapiLocalizedProductConfiguration,
      ) => {
        const aLatency = latencies.value.find((l) => l.slug === a.slug);
        const bLatency = latencies.value.find((l) => l.slug === b.slug);
        if (aLatency && bLatency) {
          return aLatency.latency < bLatency.latency ? -1 : 1;
        }

        // if one of the latencies is not available, sort it to the end
        return input.length + 1;
      },
    );
  } else {
    orderLocationsSorted.sort(
      (
        a: StrapiLocalizedProductConfiguration,
        b: StrapiLocalizedProductConfiguration,
      ) => {
        if (a.country === b.country) {
          return a.city > b.city ? 1 : -1;
        }
        return a.country > b.country ? 1 : -1;
      },
    );
  }

  sortedLocations.value = orderLocationsSorted;
};

const isCentralEuropeLocation = (
  location: StrapiLocalizedProductConfiguration,
) => location.key === 'EUW';

const centralEuropeLocation = computed<StrapiLocalizedProductConfiguration>(
  () => {
    return props.locations.find((l) => l.key === 'EUW');
  },
);

watchDebounced(
  () => latencies.value,
  () => sortLocations(props.locations),
  { deep: true, debounce: 100 },
);

// Location Dropdown
const dropdownContainer = ref<HTMLElement>();
const dropdownInstance = ref<Dropdown>();
const dropdownShown = ref<boolean>(false);

const handleBsDropdownShown = () => {
  dropdownShown.value = true;
};
const handleBsDropdownHidden = () => {
  dropdownShown.value = false;
};

useEventListener(
  dropdownContainer,
  'hidden.bs.dropdown',
  handleBsDropdownHidden,
);
useEventListener(dropdownContainer, 'shown.bs.dropdown', handleBsDropdownShown);

onMounted(async () => {
  const { default: Dropdown } = await import('bootstrap/js/dist/dropdown');
  dropdownInstance.value = new Dropdown(dropdownContainer.value);

  setTimeout(() => {
    sortLocations(props.locations);
  }, 100);
});

onUnmounted(() => {
  if (dropdownInstance.value) {
    dropdownInstance.value.dispose();
  }
});
</script>

<style lang="scss" scoped>
@import 'gportal-theme/scss/colors.scss';

.order-configuration__location {
  margin: 0 -0.5rem;

  &-toggle {
    text-transform: initial;

    letter-spacing: normal;
    border-radius: 0;

    &.show {
      background-color: $gp-dark-mouse;
      border-radius: 5px 5px 0 0;
    }

    &::after {
      content: none;
    }
  }

  &-switch {
    overflow: visible;
    z-index: 10;
    min-width: 100%;
    border-radius: 0 0 5px 5px;
    margin-top: -2px !important;

    .option {
      width: initial;
      overflow: visible;
      white-space: nowrap;
    }
  }
}
</style>
